import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/Layout/Layout";
import PageFooter from "../components/Layout/PageFooter";

const DetailTemplate = ({ data, location, pageContext }) => {
  if (data) {
    const { contentfulMetaInformation: meta, contentfulAsset: image } = data;
    return (
      <Layout location={location}>
        <Helmet>
          <title>{`Details`}</title>
        </Helmet>

        <div className="simple-title">
          <h1>{image.title}</h1>
        </div>
        <div className="container">
          <div
            className="image-detail-wrapper"
            style={{ textAlign: "center", margin: "20px 0" }}
          >
            <img src={`${image.file.url}`} style={{ maxWidth: "100%" }} />

            <div className="actions-wrapper" style={{ margin: "40px 0" }}>
              {image.description ? (
                <p>{image.description}</p>
              ) : (
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: meta.orderText.childMarkdownRemark.html,
                    }}
                  />
                  <a href={`mailto:${meta.emailOrders}`}>{meta.ordersButton}</a>
                </div>
              )}
            </div>
          </div>
        </div>
        <PageFooter />
      </Layout>
    );
  } else return null;
};

export const query = graphql`
  query($id: String!) {
    contentfulMetaInformation(metaId: { eq: "Basic" }) {
      emailOrders
      ordersButton
      orderText {
        childMarkdownRemark {
          html
        }
      }
    }
    contentfulAsset(id: { eq: $id }) {
      title
      description
      file {
        url
      }
    }
  }
`;

export default DetailTemplate;
